import MachineInfo from '@components/collector/MachineInfo';
import CollectorGraphMeasurementWrapper from '@components/CollectorGraphMeasurementWrapper/CollectorGraphMeasurementWrapper.tsx';
import CollectorMeasurementSelect from '@components/CollectorMeasurementSelect/CollectorMeasurementSelect.tsx';
import CollectorSelect from '@components/CollectorSelect/CollectorSelect.tsx';
import FarmSelect from '@components/FarmSelect/FarmSelect.tsx';
import WeatherComponent from '@components/weather/WeatherComponent';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyAggregators,
  getCompanyAllCollectorAvailableMeasurements,
  getCompanyAllCollectorParams,
  getCompanyCollectors,
  getCompanyFarms,
  selectCompanyCollectors,
} from '@store/reducers/collectorReducer.ts';
import { selectMe } from '@store/reducers/meReducer.ts';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';

Chart.register(...registerables);
Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);

const TCUAnalytics: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const me = useAppSelector(selectMe);
  const companyCollectors = useAppSelector(selectCompanyCollectors);

  const [companyFarmUuid, setCompanyFarmUuid] = useState<string>(searchParams.get('companyFarmUuid') ?? '');
  const [companyCollectorUuid, setCompanyCollectorUuid] = useState<string>(
    searchParams.get('companyCollectorUuid') ?? '',
  );
  const [dataMeasurementTypeSlug, setDataMeasurementTypeSlug] = useState<string>(
    searchParams.get('dataMeasurementTypeSlug') ?? '',
  );

  const startOfThisWeek = dayjs().startOf('week').format('YYYY-MM-DD');
  const endOfThisWeek = dayjs().endOf('week').format('YYYY-MM-DD');

  const today = dayjs().format('YYYY-MM-DD');

  const [fromTimestamp] = useState<string>(searchParams.get('fromTimestamp') ?? startOfThisWeek);
  const [toTimestamp] = useState<string>(searchParams.get('toTimestamp') ?? endOfThisWeek);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (me) {
      dispatch(getCompanyFarms());
      dispatch(getCompanyAggregators());
      dispatch(getCompanyCollectors());
      dispatch(getCompanyAllCollectorParams());
      dispatch(getCompanyAllCollectorAvailableMeasurements());
    }
  }, [me, dispatch]);

  useEffect(() => {
    const searchParms: URLSearchParamsInit = {};
    if (companyFarmUuid !== '') {
      searchParms.companyFarmUuid = companyFarmUuid;
    }
    if (companyCollectorUuid !== '') {
      searchParms.companyCollectorUuid = companyCollectorUuid;
    }
    if (dataMeasurementTypeSlug !== '') {
      searchParms.dataMeasurementTypeSlug = dataMeasurementTypeSlug;
    }
    if (fromTimestamp !== '') {
      searchParms.fromTimestamp = fromTimestamp;
    }
    if (toTimestamp !== '') {
      searchParms.toTimestamp = toTimestamp;
    }
    setSearchParams(searchParms);
  }, [setSearchParams, companyFarmUuid, companyCollectorUuid, dataMeasurementTypeSlug, fromTimestamp, toTimestamp]);

  useEffect(() => {
    if (companyCollectorUuid) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [companyCollectorUuid, today, dispatch]);

  return (
    <div className="site_level_analysis p-4 sm:ml-64">
      <div className="mb-28 rounded-lg border-2 border-dashed border-gray-200 p-4 dark:border-gray-700">
        <div className="items-left justify-lef mb-4 flex rounded bg-gray-50 px-3 py-3 dark:bg-gray-800">
          <p className="text-2xl text-tlBlue dark:text-gray-500">Site Level Analysis</p>
        </div>
        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-1">Select Site</h5>
            <FarmSelect setFarmUuid={setCompanyFarmUuid} initialValue={companyFarmUuid} />
          </div>
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-1">Select Collector </h5>
            <CollectorSelect
              farmUuid={companyFarmUuid}
              setCollectorUuid={setCompanyCollectorUuid}
              initialValue={companyCollectorUuid}
              disabled={companyFarmUuid === ''}
            />
          </div>
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-4">Select Data</h5>
            <CollectorMeasurementSelect
              collectorUuid={companyCollectorUuid}
              setDataMeasurementTypeSlug={setDataMeasurementTypeSlug}
              initialValue={dataMeasurementTypeSlug}
              disabled={companyFarmUuid === ''}
            />
          </div>
        </div>

        {companyCollectorUuid !== '' && companyCollectors[companyCollectorUuid] !== undefined ? (
          <>
            <div className="basic-card mb-4 grid grid-rows-1 gap-4">
              <div className="col-span-4">
                <h5 className="tl-medium-heading">Selected Collector</h5>
                <h5 className="text-sm font-semibold text-tlOrange">{companyCollectors[companyCollectorUuid].name}</h5>
              </div>
              <WeatherComponent collector={companyCollectors[companyCollectorUuid]} />
            </div>
            <div className="basic-card mb-4">
              {dataMeasurementTypeSlug ? (
                <CollectorGraphMeasurementWrapper
                  collector={companyCollectors[companyCollectorUuid]}
                  dataMeasurementTypeSlug={dataMeasurementTypeSlug}
                  searchParams={searchParams}
                  fromTimestamp={fromTimestamp}
                  toTimestamp={toTimestamp}
                />
              ) : (
                <></>
              )}
            </div>

            {isLoading ? (
              <div className="loading-spinner">Loading...</div>
            ) : (
              <div className="basic-card items-left mb-4 flex flex-col">
                {/*<div className="flex flex-wrap">*/}
                {/*  <div className="w-full p-2 sm:w-1/2">*/}
                {/*    <TCUAngleSetpointGraph collectorUuid={companyCollectorUuid} />*/}
                {/*  </div>*/}
                {/*  <div className="w-full p-2 sm:w-1/2">*/}
                {/*    <TCUAngleCurrentGraph collectorUuid={companyCollectorUuid} />*/}
                {/*  </div>*/}
                {/*  <div className="w-full p-2 sm:w-1/2">*/}
                {/*    <TCUBatteryChargeStatusGraph collectorUuid={companyCollectorUuid} />*/}
                {/*  </div>*/}
                {/*  <div className="w-full p-2 sm:w-1/2">*/}
                {/*    <TCUBatteryCurrentGraph collectorUuid={companyCollectorUuid} />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            )}

            <div className="items-left mb-4 flex justify-between rounded bg-gray-50 px-3 py-3 dark:bg-gray-800">
              <p className="text-2xl text-tlBlue dark:text-gray-500">{companyCollectors[companyCollectorUuid].name}</p>
              <button className="graph-button">Rename TCU Inactive</button>
            </div>
            <div className="basic-card mb-4">
              <MachineInfo collector={companyCollectors[companyCollectorUuid]} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TCUAnalytics;
