import CollectorGraphMeasurements from '@components/CollectorGraphMeasurements/CollectorGraphMeasurements';
import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { InterfaceCollectorMeasurementAverage } from '@interfaces/InterfaceCollectorMeasurementAverage.ts';
import { DateTimePicker } from '@mantine/dates';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyCollectorMeasurementAverage,
  selectCompanyCollectorAvailableMeasurements,
  selectCompanyCollectorMeasurementAverage,
} from '@store/reducers/collectorReducer.ts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type CollectorGraphMeasurementWrapperProps = {
  collector: InterfaceCollector;
  dataMeasurementTypeSlug: string;
  searchParams: URLSearchParams;
  fromTimestamp?: string;
  toTimestamp?: string;
};

export type InterfaceGraphPeriods = 'day' | 'week' | 'month' | 'year';

export default function CollectorGraphMeasurementWrapper({
  collector,
  dataMeasurementTypeSlug,
  searchParams: urlSearchParams,
}: CollectorGraphMeasurementWrapperProps) {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const companyCollectorAvailableMeasurements = useAppSelector(
    selectCompanyCollectorAvailableMeasurements(collector.uuid),
  );
  let companyCollectorAvailableMeasurement;
  if (companyCollectorAvailableMeasurements && companyCollectorAvailableMeasurements[dataMeasurementTypeSlug]) {
    companyCollectorAvailableMeasurement = companyCollectorAvailableMeasurements[dataMeasurementTypeSlug];
  }

  const [period, setPeriod] = useState<InterfaceGraphPeriods>('week');

  const startOfTime = dayjs().startOf(period).format('YYYY-MM-DD HH:mm');
  const endOfThisTime = dayjs().endOf(period).format('YYYY-MM-DD HH:mm');

  const [fromTimestamp, setFromTimestamp] = useState<string>(urlSearchParams.get('fromTimestamp') ?? startOfTime);
  const [toTimestamp, setToTimestamp] = useState<string>(urlSearchParams.get('toTimestamp') ?? endOfThisTime);

  useEffect(() => {
    const urlVarsReplaceMap = {
      '{collectorUuid}': collector.uuid,
      '{measurementTypeSlug}': dataMeasurementTypeSlug,
      '{fromTimestamp}': fromTimestamp,
      '{toTimestamp}': toTimestamp,
      '{exportType}': '',
    };
    // dispatch(
    //   getCompanyCollectorMeasurement({
    //     urlVarsReplaceMap,
    //   }),
    // );
    dispatch(
      getCompanyCollectorMeasurementAverage({
        urlVarsReplaceMap,
      }),
    );
  }, [fromTimestamp, toTimestamp, dataMeasurementTypeSlug, dispatch, collector]);

  const handleFromTimestampChange = (value: Date | null) => {
    if (value) {
      const newFromTimestamp = dayjs(value).format('YYYY-MM-DD HH:mm');
      setFromTimestamp(newFromTimestamp);
      const updatedSearchParams = new URLSearchParams(urlSearchParams);
      updatedSearchParams.set('fromTimestamp', newFromTimestamp);
      setSearchParams(updatedSearchParams);
    }
  };

  const handleToTimestampChange = (value: Date | null) => {
    if (value) {
      const newToTimestamp = dayjs(value).format('YYYY-MM-DD HH:mm');
      setToTimestamp(newToTimestamp);
      const updatedSearchParams = new URLSearchParams(urlSearchParams);
      updatedSearchParams.set('toTimestamp', newToTimestamp);
      setSearchParams(updatedSearchParams);
    }
  };

  // const companyCollectorMeasurement = useAppSelector(
  //   selectCompanyCollectorMeasurement(collector.uuid),
  // );

  const companyCollectorMeasurementAverage: InterfaceCollectorMeasurementAverage | null = useAppSelector(
    selectCompanyCollectorMeasurementAverage(collector.uuid),
  );

  const changePeriod = (period: InterfaceGraphPeriods) => {
    setFromTimestamp(dayjs().startOf(period).format('YYYY-MM-DD HH:mm'));
    setToTimestamp(dayjs().endOf(period).format('YYYY-MM-DD HH:mm'));
    setPeriod(period);
  };

  return companyCollectorAvailableMeasurement ? (
    <>
      <div className="-mb-9 grid grid-cols-2">
        <div></div>
        <div>
          <div className="grid grid-cols-12 items-center">
            <label
              className="col-span-1 pr-1 text-right text-sm font-semibold text-orange-500"
              htmlFor="fromDatePicker">
              From
            </label>
            <div className="col-span-5">
              <DateTimePicker
                id="fromDatePicker"
                className="custom-picker"
                valueFormat="YYYY-MMM-DD HH:mm"
                value={dayjs(fromTimestamp).toDate()}
                placeholder="Pick from date"
                onChange={handleFromTimestampChange}
              />
            </div>
            <label className="col-span-1 pr-1 text-right text-sm font-semibold text-orange-500" htmlFor="toDatePicker">
              To
            </label>
            <div className="col-span-5">
              <DateTimePicker
                id="toDatePicker"
                className="custom-picker"
                valueFormat="YYYY-MMM-DD HH:mm"
                value={dayjs(toTimestamp).toDate()}
                placeholder="Pick to date"
                onChange={handleToTimestampChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1">
        <div className="graph-buttons-timeframe flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="mr-4 size-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
            />
          </svg>

          <button className="graph-button" onClick={() => changePeriod('month')}>
            This Month
          </button>
          <button className="graph-button" onClick={() => changePeriod('week')}>
            Last 7 Days
          </button>
          <button className="graph-button" onClick={() => changePeriod('day')}>
            Today
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {companyCollectorMeasurementAverage && companyCollectorMeasurementAverage.data ? (
          <CollectorGraphMeasurements collectorMeasurements={companyCollectorMeasurementAverage} period={period} />
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <> </>
  );
}
