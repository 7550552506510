import { InterfaceGraphPeriods } from '@components/CollectorGraphMeasurementWrapper/CollectorGraphMeasurementWrapper.tsx';
import { InterfaceCollectorMeasurementAverage } from '@interfaces/InterfaceCollectorMeasurementAverage.ts';
import { InterfaceCollectorMeasurements } from '@interfaces/InterfaceCollectorMeasurements.ts';
import { useAppSelector } from '@store/appStore.ts';
import { selectDataMeasurementType } from '@store/reducers/dataReducer.ts';
import { chartGetData } from '@utils/chartGetData.ts';
import { Chart, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

type TimeUnits = 'day' | 'hour';

export type CollectorGraphMeasurementsProps = {
  collectorMeasurements: InterfaceCollectorMeasurements | InterfaceCollectorMeasurementAverage;
  period: InterfaceGraphPeriods;
};

export default function CollectorGraphMeasurements({
  collectorMeasurements,
  period = 'week',
}: CollectorGraphMeasurementsProps) {
  const { unitName, unit } = collectorMeasurements;
  const data = useMemo(() => chartGetData(collectorMeasurements, period), [collectorMeasurements, period]);
  const [timeunit] = useState<TimeUnits>('day');
  const measurementTypes = useAppSelector(selectDataMeasurementType(collectorMeasurements.measurementTypeSlug));

  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeunit,
          displayFormats: {
            day: 'MMM D',
            hour: 'HH:mm',
          },
          tooltipFormat: 'YY/MM/DD HH:mm',
          round: 'minute',
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
        },
        title: {
          display: true,
          text: 'DATE',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `${unitName} (${unit})`,
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: measurementTypes.name ?? collectorMeasurements.measurementTypeSlug ?? '',
      },
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
    },
  };

  if (
    collectorMeasurements.measurementTypeSlug === 'tcu-angle-current' ||
    collectorMeasurements.measurementTypeSlug === 'tcu-angle-setpoint'
  ) {
    if (!options.scales) {
      options.scales = {
        x: {
          time: {},
        },
      };
    }
    if (!options.scales.x) {
      options.scales.x = {
        time: {},
      };
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    options.scales.x.time = {
      unit: timeunit,
      displayFormats: {
        unit: 'hour',
        displayFormats: {
          hour: 'HH-MM',
        },
        tooltipFormat: 'YY/MM/DD HH:mm',
      },
    };
    options!.scales!.x!.ticks = {
      source: 'auto',
      autoSkip: false,
      maxRotation: 0,
      minRotation: 0,
    };
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}
