import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { getCompanyCollectorWeather, selectCompanyCollectorWeather } from '@store/reducers/collectorReducer.ts';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export interface WeatherComponentProps {
  collector: InterfaceCollector;
}

const WeatherComponent = ({ collector }: WeatherComponentProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompanyCollectorWeather({ collector }));
  }, [collector, collector.uuid, dispatch]);

  const companyCollectorWeather = useAppSelector(selectCompanyCollectorWeather(collector.uuid));

  return companyCollectorWeather && companyCollectorWeather.weatherData ? (
    <>
      <div className="col-span-3 col-start-5">
        <div>
          {companyCollectorWeather.weatherData &&
            dayjs(companyCollectorWeather.weatherData.dt * 1000).format('MMMM D, YYYY HH:mm:ss')}
          <h5 className="mt-2 text-sm font-semibold text-tlOrange">No Collector Warnings</h5>
        </div>
      </div>
      <div className="col-span-5 col-start-8">
        {companyCollectorWeather.weatherData && companyCollectorWeather.weatherData.weather && (
          <div className="flex items-center">
            <img
              src={`https://openweathermap.org/img/wn/${companyCollectorWeather.weatherData.weather[0].icon}.png`}
              alt="Weather icon"
            />
            <div>
              <h2>{companyCollectorWeather.weatherData.name}</h2>
              <div>{companyCollectorWeather.weatherData.weather[0].description}</div>
              <div>Wind Speed: {companyCollectorWeather.weatherData.wind.speed} m/s</div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default WeatherComponent;
